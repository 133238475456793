import React, { useState, useEffect, useContext } from 'react';
import PerformanceChart from './PerformanceChart';
import styles from './HistoricalOverview.module.css';
import Loader from '../Loader/Loader.js';
import Tooltip from '../Tooltip/Tooltip.js';
import { BASE_URL } from '../../constants/constants';
import axiosInstance from '../../utils/axiosInstance';
import { AuthContext } from '../../AuthWrapper/AuthWrapper.js';
import { useDisconnect } from '@web3modal/ethers/react';
import { useErrorContext } from '../../context/ErrorContext.js';

function HistoricalOverviewModel({ dashboardState, initialModel, initialProjectId }) {
  const [selectedModel, setSelectedModel] = useState();
  const [selectedProjectId, setSelectedProjectId] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isProjectDropdownOpen, setIsProjectDropdownOpen] = useState(false);
  const [isModelDropdownOpen, setIsModelDropdownOpen] = useState(false);
  const [data, setData] = useState({ close: [], model: [] });
  const [models, setModels] = useState([]);
  const [projectIds, setProjectIds] = useState([]);
  const { disconnect } = useDisconnect();
  const { state, send } = useContext(AuthContext);
  const { axiosInstance } = useErrorContext();
  
  useEffect(() => {
    if (dashboardState) {
      const availableModels = Object.keys(dashboardState);
      setModels(availableModels);
      
      const currentModel = initialModel && availableModels.includes(initialModel)
        ? initialModel
        : availableModels[0];
      setSelectedModel(currentModel);
      
      const availableProjectIds = dashboardState[currentModel] || [];
      setProjectIds(availableProjectIds);
      
      // Prioritize initialProjectId if it exists and is valid
      const currentProjectId = initialProjectId && availableProjectIds.includes(initialProjectId)
        ? initialProjectId
        : availableProjectIds[0];
      setSelectedProjectId(currentProjectId);
    } else {
      // Reset state when dashboardState is null
      setModels([]);
      setSelectedModel(null);
      setProjectIds([]);
      setSelectedProjectId(null);
      setData({ close: [], model: [] });
    }
  }, [dashboardState, initialModel, initialProjectId]);

  useEffect(() => {
    if (selectedModel && selectedProjectId) {
      fetchData(selectedModel, selectedProjectId);
    }
  }, [selectedModel, selectedProjectId]);

  const fetchData = async (model, projectId) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get('/model_history', {
        params: {
          model: model,
          project_id: projectId,
          // start_date: '2024-05-19',
          // threshold: 1.5,
          // size: 0.05
        }
      });
      const result = response.data;
      setData(result.time_series);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        send({ type: "SIGN_OUT" });
        localStorage.removeItem("authState");
        disconnect();
      }
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleProjectDropdown = () => setIsProjectDropdownOpen(!isProjectDropdownOpen);
  const toggleModelDropdown = () => setIsModelDropdownOpen(!isModelDropdownOpen);

  const selectProjectId = (projectId) => {
    setSelectedProjectId(projectId);
    setIsProjectDropdownOpen(false);
  };

  const selectModel = (model) => {
    setSelectedModel(model);
    const newProjectIds = dashboardState[model] || [];
    setProjectIds(newProjectIds);
    setSelectedProjectId(newProjectIds[0]);
    setIsModelDropdownOpen(false);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.overviewHeader}>
        <div className={styles.headerContainer}>
          <div className={styles.headerText}>Historical Overview</div>
          <Tooltip id="historical-overview" page="model-page" />
        </div>
        <div className={styles.mainDropdownContainer}>
          <div className={styles.dropdownContainer}>
            <div className={styles.dropdown} onClick={toggleModelDropdown}>
              <span className={styles.selectedOption}>{selectedModel}</span>
              <span className={styles.arrow}>
                {isModelDropdownOpen ? (
                  <img className={styles.invertedArrow} src='/images/arrowDown.svg' alt='arrow down' />
                ) : (
                  <img src='/images/arrowDown.svg' alt='arrow down' />
                )}
              </span>
            </div>
            {isModelDropdownOpen && (
              <div className={styles.dropdownMenu}>
                {models.map((model) => (
                  <div key={model} className={styles.dropdownItem} onClick={() => selectModel(model)}>
                    {model}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className={styles.dropdownContainer}>
            <div className={styles.dropdown} onClick={toggleProjectDropdown}>
              <span className={styles.selectedOption}>
                {/* <img src={`/images/${selectedProjectId?.toLowerCase()}.svg`} alt={selectedProjectId} /> */}
                {selectedProjectId}
              </span>
              <span className={styles.arrow}>
                {isProjectDropdownOpen ? (
                  <img className={styles.invertedArrow} src='/images/arrowDown.svg' alt='arrow down' />
                ) : (
                  <img src='/images/arrowDown.svg' alt='arrow down' />
                )}
              </span>
            </div>
            {isProjectDropdownOpen && (
              <div className={styles.dropdownMenu}>
                {projectIds.map((projectId) => (
                  <div
                    key={projectId}
                    className={styles.dropdownItem}
                    onClick={() => selectProjectId(projectId)}
                  >
                    {/* <img src={`/images/${projectId?.toLowerCase()}.svg`} alt={projectId} /> */}
                    {projectId}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.chartContainer}>
        <div className={styles.chartBG}>
          <img src='images/chartBG.png' />
        </div>
        {isLoading && (
          <div>
            {/* <PerformanceChart data={data} /> */}
            <Loader />
          </div>
        )}
        <PerformanceChart data={data} />
      </div>
    </div>
  );
}

export default React.memo(HistoricalOverviewModel);
