import React, { useState, useEffect,useContext } from 'react';
import CustomGauge from './Gauge.js';
import styles from './Technicals.module.css';
import Tooltip from '../Tooltip/Tooltip';
import { BASE_URL } from '../../constants/constants';
import axiosInstance from '../../utils/axiosInstance';
import { AuthContext } from '../../AuthWrapper/AuthWrapper.js';
import { extractProjectIds } from '../../utils/helpers.js';

function Technicals({ dashboardState }) {
  const [selectedProjectId, setSelectedProjectId] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [residuals, setResiduals] = useState({});
  const { state, send } = useContext(AuthContext);


  useEffect(() => {
    if (dashboardState) {
      const uniqueProjectIds = extractProjectIds(dashboardState);
      setSelectedProjectId(uniqueProjectIds[0]);
    }
  }, [dashboardState]);

  useEffect(() => {
    const fetchData = async () => {
        const oracleData = state.context?.modelState?.oracle      
        const newResiduals = oracleData?.[selectedProjectId];
        setResiduals(newResiduals);
    };

    if (selectedProjectId  && state?.context?.modelState &&  Object.keys(state?.context?.modelState).length > 0) {
      fetchData();
    }
  }, [selectedProjectId,state.context]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectProjectId = (projectId) => {
    send({
      type:"UPDATE_MODEL_STATE",
    })
    setSelectedProjectId(projectId);
    setIsOpen(false);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.topContainer}>
        <div className={styles.headingContainer}>
          <div className={styles.heading}>Technicals</div>
          <Tooltip id="technicals" page="home-page" />
        </div>
        <div className={styles.dropdownContainer}>
          <div className={styles.dropdown} onClick={toggleDropdown}>
            <span className={styles.selectedOption}>{selectedProjectId}</span>
            <span className={styles.arrow}>
              {isOpen ? (
                <img
                  className={styles.invertedArrow}
                  src="/images/arrowDown.svg"
                  alt="arrow down"
                />
              ) : (
                <img src="/images/arrowDown.svg" alt="arrow down" />
              )}
            </span>
          </div>
          {isOpen && (
            <div className={styles.dropdownMenu}>
              {state.context?.projectIds.map((projectId) => (
                <div
                  key={projectId}
                  className={styles.dropdownItem}
                  onClick={() => selectProjectId(projectId)}
                >
                  {projectId}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className={styles.gaugeContainer}>
        <img
          className={styles.mask}
          src="/images/technicalsMask.svg"
          alt="mask"
        />
        <CustomGauge residual={residuals} />
      </div>
    </div>
  );
}

export default Technicals;
