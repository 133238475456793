import React from 'react';
import styles from './ConnectModal.module.css';
import { useWeb3Modal } from '@web3modal/ethers/react';
import { MinTokenAmountLayout } from '../../constants/constants.js'; // Import the constant
import { useWalletContext } from '../../context/WalletContext.js'; // Import the context
import { useErrorContext } from "../../context/ErrorContext.js";

const ConnectModal = () => {
  const { error } = useErrorContext();
  const { open } = useWeb3Modal();
  const { tokenSymbol } = useWalletContext(); // Use the context to get the token symbol

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.title}>Please connect your wallet</div>
        <div className={styles.subtitle}>
          You must hold {MinTokenAmountLayout} $YAI to use the dashboard
        </div>
        {error && (
          <div
            className={styles.buttonWallet}
            style={{ color: "red", marginBottom: "16px", fontSize: "14px" }}
          >
            {error}
          </div>
        )}
        <div className={styles.buttonWallet} onClick={() => open()}>
          <img src='/images/walletIcon.svg' alt='Wallet' />
          Wallet
        </div>
      </div>
    </div>
  );
};

export default ConnectModal;
